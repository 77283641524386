defineDs('DanskeSpil/Framework/Survey/Scripts/Survey', function () {

  const surveyInit = () => {

    const scriptTag = document.getElementById('survey-data');

    if (!scriptTag) {
      return;
    }

    const jsonData = scriptTag.textContent || scriptTag.innerText;
    const surveyData = JSON.parse(jsonData);

    surveyData.surveys.forEach((survey) => {

      if (survey.trigger === 'pageload') {
        surveyLoad('survey1to5', survey.id, survey.exposureLimit, survey.delay);
      }
      if (survey.trigger === 'eventTrigger_Receipt') {
        document.addEventListener('eventTrigger:receipt', function () {
          surveyLoad('survey1to5', survey.id, survey.exposureLimit, survey.delay);
        }, { once: true });
      }
    });

  };

  // eslint-disable-next-line no-unused-vars
  const surveyFetchHTMLTemplate = (templateName) => {
    const templatePath = `/Components/DanskeSpil/Framework/Survey/templates/${templateName}.html`;
    // eslint-disable-next-line compat/compat
    return fetch(templatePath)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Failed to fetch template: ${templatePath}`);
        }
        return response.text();
      }).then((htmlString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        const IdsToSearchFor = ['form', 'afterSubmit'];
        const elements = [];

        IdsToSearchFor.forEach((id) => {
          const element = doc.getElementById(id);
          if (element) {
            let templateContent = element.content.firstElementChild;
            if (templateContent) {
              for (let attr of element.attributes) {
                let attrName = attr.name;
                if (attr.name === 'id') { attrName = 'templatePartId'; }
                templateContent.setAttribute(attrName, attr.value);
              }
            }
            elements.push(templateContent.outerHTML);
          }
        });
        return elements;
      })
      .catch((error) => {
        console.error('Error fetching or parsing HTML:', error);
      });
  };

  // eslint-disable-next-line no-unused-vars
  const surveyFetchData = (campaignId) => {
    const surveyApiUrl = `/${DS.Config.CONTEXT}/scapi/danskespil/survey/campaign/${campaignId}`;
    // eslint-disable-next-line compat/compat
    return fetch(surveyApiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Failed to fetch survey data for campaign: ${campaignId}`);
        }
        return response.json();
      });
  };

  // eslint-disable-next-line no-unused-vars
  const surveyFetchIsThrottling = (campaignId, exposedIds) => {
    const surveyThrottlingApiUrl = `/${DS.Config.CONTEXT}/scapi/danskespil/survey/campaign/${campaignId}/throttling`;
    // eslint-disable-next-line compat/compat
    return fetch(surveyThrottlingApiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(exposedIds)
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Failed to fetch survey data for campaign: ${campaignId}`);
        }
        return response.json();
      })
      .catch((error) => {
        console.error('Network or other error:', error);
        throw error; // Re-throw the error after logging
      });
  };

  // eslint-disable-next-line no-unused-vars
  const surveyFetchSendResponse = (campaignId, data) => {
    const url = `/${DS.Config.CONTEXT}/scapi/danskespil/survey/campaign/${campaignId}/response`;
    // eslint-disable-next-line compat/compat
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Failed to send survey data for campaign: ${campaignId}`);
        }
        return response.json();
      })
      .then((responseData) => {
        console.log('Success:', responseData);
        return responseData;
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  // eslint-disable-next-line no-unused-vars
  const surveyBuildContainer = (surveyData, campaignId) => {
    const surveyContainer = document.createElement('div');
    surveyContainer.classList.add('survey-container');
    surveyContainer.id = 'survey-container--' + campaignId;
    surveyContainer.innerHTML = surveyData;
    // eslint-disable-next-line compat/compat
    document.body.appendChild(surveyContainer);
  };

  // eslint-disable-next-line no-unused-vars
  const surveyLoad = async (templateName, campaignId, exposedIds, delayBeforeShow) => {
    const surveyData = await surveyFetchData(campaignId);
    const customerId = JSON.parse(sessionStorage.getItem('DLODS_USER_SESSION'))?.customerInfo?.customerId || '';
    const cookieFound = surveyGetCookie(`DS_SURVEY_COOKIE_${customerId}_${surveyData?.throttlingId}`) !== null;

    let loadSurvey = await surveyFetchIsThrottling(campaignId, exposedIds);
    if (!loadSurvey?.isThrottled) {
      return;
    }

    if (!cookieFound) {
      surveySetCookie(`DS_SURVEY_COOKIE_${customerId}_${surveyData?.throttlingId}`, 'true', surveyData?.throttlingDays || 1);

      let htmlContent = await surveyFetchHTMLTemplate(templateName);
      setTimeout(() => {
        htmlContent = surveyReplaceText(htmlContent, surveyData);
        surveyBuildContainer(htmlContent.join(''), campaignId);
        // eslint-disable-next-line no-unused-vars
        surveyWaitForElement('#survey-container--' + campaignId, (element) => {
          element.data = surveyData;
          surveyAddEventListenersToForm(campaignId);
        });
      }, delayBeforeShow * 1000);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const surveyReplaceText = (htmlContent, surveyData) => {
    let replacedHtmlContent = [];
    htmlContent.forEach((element) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(element, 'text/html');

      Object.entries(surveyData).forEach(([key, value]) => {
        const el = doc.getElementById('survey_' + key);
        if (el) {
          el.innerHTML = value;
        }
      });
      replacedHtmlContent.push(doc.documentElement.outerHTML);
    });
    return replacedHtmlContent;
  };

  // eslint-disable-next-line no-unused-vars
  const surveyAddEventListenersToForm = (campaignId) => {
    console.log('addEvents');
    const form = document.getElementById('survey-container--' + campaignId);
    if (form) {
      const submit = form.querySelector('#survey_submit');
      if (submit) {
        submit.addEventListener('click', (event) => {
          event.preventDefault();
          surveySendData(campaignId, event.target || event.currentTarget);
        });
      }
      const closeItems = form.querySelectorAll('.survey__close');
      closeItems.forEach((item) => {
        item.addEventListener('click', (event) => {
          event.preventDefault();
          form.remove();
        });
      });

      const radioGroups = form.querySelectorAll('.survey__radio-group[mandatory]');
      radioGroups.forEach((radioGroup) => {
        const radios = radioGroup.querySelectorAll('input[type="radio"]');

        radios.forEach((radio) => {
          radio.addEventListener('click', () => {
            const submitButton = form.querySelector('#survey_submit');
            console.log('form', form.data);
            if (submitButton) {
              submitButton.disabled = !surveyValidateForm(campaignId);
            }
          });
        });
      });
    }
  };

  // eslint-disable-next-line no-unused-vars
  const surveyWaitForElement = (selector, callback) => {
    const element = document.querySelector(selector);

    if (element) {
      callback(element);
    } else {
      // eslint-disable-next-line no-unused-vars
      const observer = new MutationObserver((mutationsList, observer) => {
        const element = document.querySelector(selector);
        if (element) {
          observer.disconnect();
          callback(element);
        }
      });
      observer.observe(document.body, { childList: true, subtree: true });
    }
  };

  // eslint-disable-next-line no-unused-vars
  const surveySerializeForm = (campaignId) => {
    const form = document.getElementById('survey-container--' + campaignId);
    const inputs = form.querySelectorAll('input[type="radio"], textarea');

    const formData = Array.from(inputs).reduce((acc, input) => {
      input.name = input?.name.replace('survey_', '');
      if (input.type === 'radio') {
        if (input.checked) {
          acc[input.name] = input.value;
        }
      } else if (input.tagName.toLowerCase() === 'textarea') {
        acc[input.name] = input.value;
      }
      return acc;
    }, {});

    return formData;
  };

  // eslint-disable-next-line no-unused-vars
  const surveyValidateForm = (campaignId) => {
    const form = document.getElementById('survey-container--' + campaignId);
    const elements = form.querySelectorAll('input[type="radio"], textarea');
    let validForm = true;
    elements.forEach((element) => {
      if (element.type === 'radio' && element.checked) {
        const radioGroup = element.closest('.survey__radio-group');
        if (radioGroup && radioGroup.hasAttribute('mandatory') && element.value.trim() === '') {
          validForm = false;
        }
      }
      if (element.tagName.toLowerCase() === 'textarea') {
        if (element.hasAttribute('mandatory') && element.value.trim() === '') {
          validForm = false;
        }
      }
    });
    return validForm;
  };

  // eslint-disable-next-line no-unused-vars
  const surveyGetCookie = (name) => {
    const cookies = document.cookie;
    const match = cookies.match(new RegExp('(^| )' + name + '=([^;]+)'));
    return match ? decodeURIComponent(match[2]) : null;
  };

  // eslint-disable-next-line no-unused-vars
  const surveySetCookie = (name, value, days) => {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + encodeURIComponent(value) + expires + '; path=/';
  };

  // eslint-disable-next-line no-unused-vars
  const surveySwitchTemplatePartId = (element) => {
    if (element) {
      const currentSurveyForm = element.closest('.survey__form');
      if (currentSurveyForm) {
        const next = currentSurveyForm.getAttribute('next');
        if (next) {
          const surveyContainer = currentSurveyForm.closest('.survey-container');
          const nextForm = surveyContainer.querySelector(`[templatepartid="${next}"]`);
          currentSurveyForm.setAttribute('hidden', '');
          if (nextForm) {
            nextForm.removeAttribute('hidden');
          }
        }
      }
    }
  };

  // eslint-disable-next-line no-unused-vars
  const surveySendData = async (campaignId, submitter) => {
    if (surveyValidateForm(campaignId)) {
      const data = surveySerializeForm(campaignId);
      const response = await surveyFetchSendResponse(campaignId, data);
      if (!response.status) {
        console.log('user has already submitted');
      }
      surveySwitchTemplatePartId(submitter);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const surveyTracking = (category, action, label) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'gaEvent',
        eventSyntax: {
          action: action,
          category: category,
          label: label
        }
      });
    }
  };

  surveyInit();

});
