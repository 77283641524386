defineDs('DanskeSpil/Domain/Feature.Navigation/Scripts/NavigationAccountMenu', [
  'Common/Framework/EventHandling/Scripts/CrossWindowEvents',
  'Shared/Framework/Mithril/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/Authentification/Scripts/LoginController',
  'DanskeSpil/Domain/Feature.Components/Scripts/Utils/IsLoggedIn',
], function (CrossWindowEvents, Utils, LoginController, IsLoggedIn) {
  const menu = document.querySelector('.js-navigation-account-menu');

  if (!menu) {
    return;
  }

  const mainMenuAccountCta = document.querySelector('.js-navigation .js-navigation-main-menu-account-cta');
  const logOutCta = menu.querySelector('.js-account-menu-logout-trigger');

  const logOut = () => {
    LoginController.logout({
      callback: function () {
        closeMenu();

        // On some pages (eg game pages), they want you redirect back to a specific page after logout
        const overrideReturnUrl = document.body.getAttribute('data-override-logout-return-url');
        if (overrideReturnUrl) {
          window.location.href = overrideReturnUrl;
          return;
        }

        // MIT-1812: Hack for ensuring app logout when Tips, Maaljagt and Dantoto is embedded
        const cookieLogoutSuccessUrl = Utils.cookie('OverrideLogoutUrl');
        if (cookieLogoutSuccessUrl) {
          window.location.href = cookieLogoutSuccessUrl;
        }
      }
    });
  };

  const openMenu = () => {
    document.dispatchEvent(new CustomEvent('navigation:menu:open', { detail: { menu } }));
  };

  const closeMenu = () => {
    document.dispatchEvent(new CustomEvent('navigation:menu:close', { detail: { menu } }));
  };

  const setNotLoggedInLabel = () => mainMenuAccountCta.innerText = 'Log ind';

  const initialize = async () => {
    // Add event listeners
    const openMenuToggle = document.querySelector('.js-navigation-open-account-menu');
    openMenuToggle?.addEventListener('click', openMenu);

    const closeMenuToggle = menu.querySelector('.js-navigation-close-account-menu');
    closeMenuToggle?.addEventListener('click', closeMenu);

    const regionInfoOpen = menu.querySelector('.js-navigation-region-info');
    regionInfoOpen?.addEventListener('click', (event) => {
      const modalContent = event.target.getAttribute('data-modal-content');

      if (!modalContent) {
        return;
      }

      const { title, text } = JSON.parse(modalContent);

      document.dispatchEvent(new CustomEvent('modal:open', { detail: { title, text } }));
    });

    document.addEventListener('navigation:accountMenu:close', closeMenu);

    logOutCta?.addEventListener('click', logOut);

    // Wait for the isUserLoggedIn utility function to determine the login status
    const loggedIn = await IsLoggedIn();

    if (!loggedIn || document.body.classList.contains('is-context-neutral')) {
      setNotLoggedInLabel();
    }
  };

  initialize();

  CrossWindowEvents.subscribe('ds.event.userLoggedOut', setNotLoggedInLabel);

});


