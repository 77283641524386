defineDs('DanskeSpil/Domain/Feature.Components/Scripts/Notifications', [
  'Shared/Framework/Mithril/Scripts/Helpers/Utils',
  'Shared/Framework/Mithril/Scripts/Helpers/Dictionary',
  'Shared/Framework/Mithril/Scripts/Helpers/Storage',
  'Common/Framework/EventHandling/Scripts/CrossWindowEvents',
  'Common/Framework/EventHandling/Scripts/Event',
  'DanskeSpil/Domain/Authentification/Scripts/LoginCache',
  'DanskeSpil/Domain/Authentification/Scripts/SitecoreAuth',
  'DanskeSpil/Domain/Feature.Components/Scripts/Utils/IsLoggedIn',
], function (Utils, Dictionary, Storage, CrossWindowEvents, Event, LoginCache, SitecoreAuth, IsLoggedIn) {

  const hasToast = !!document.querySelector('.js-toasts');
  if (!hasToast) return;

  let authenticationDictionary = null; // For lazy-loading purpose, this will be initialized from getDictionaryText.

  // Returns boolean depending on whether or not the localStorage contains the lastLoginHash
  const shouldShowLastLogin = () => {
    const now = (new Date).getTime();
    const lastLoginExpiration = Number(Storage.get('lastLoginExpiration'));
    const cookieValue = Utils.cookie('BOSSOisLoggedIn' + DS.Config.BOSSOCONTEXT);

    if (Storage.get('lastLoginHash') !== cookieValue) {
      Storage.set('lastLoginHash', cookieValue);
      Storage.set('lastLoginExpiration', now + 30000);
      return true;
    } else if (now <= lastLoginExpiration) {
      return true;
    }
    return false;
  };

  const getUserInfo = (callback) => {
    const userObj = LoginCache.getUserObj();
    if (!userObj || !userObj.customerInfo) {
      SitecoreAuth.getUserObj(function (data) {
        if (data && data.customerInfo) {
          callback(null, data);
        } else {
          callback(true, null);
        }
      });
    } else {
      callback(null, userObj);
    }
  };

  const getDictionaryText = (dictionaryElement, callback) => {
    // Lazy-loading: If dictionary is not initialized yet, please do:
    if (!authenticationDictionary) {
      authenticationDictionary = new Dictionary('/Authentification');
    }

    authenticationDictionary.ready.then(() => {
      const text = authenticationDictionary.get(dictionaryElement);
      if (text) {
        callback(text);
      }
    });
  };

  const gameScannerNotification = (user) => {
    // game scan (dataprocessing)
    if (!user.customerInfo.hasConsentedToDataprocessing && !user.customerInfo.isRetailAccount) {
      getDictionaryText('GameScanNotAccepted', (text) => {
        document.dispatchEvent(new CustomEvent('toast:open', { detail: { text, id: 'gamescanner-notification' } }));
      });
    } else {
      document.dispatchEvent(new CustomEvent('toast:close', { detail: { id: 'gamescanner-notification' } }));
    }

    // and pending nofification
    if (user.customerInfo.status === 'Pending' && !user.customerInfo.isRetailAccount) {
      getDictionaryText('UserPending', (text) => {
        document.dispatchEvent(new CustomEvent('toast:open', { detail: { text, id: 'pending-notification' } }));
      });
    } else {
      document.dispatchEvent(new CustomEvent('toast:close', { detail: { id: 'pending-notification' } }));
    }
  };

  const userUpdated = () => {
    getUserInfo((err, user) => {
      if (!err) {
        gameScannerNotification(user);

        if (user.customerInfo.prevLoginTime && shouldShowLastLogin()) {
          // The time the notification is shown should be 30s regardless of how many pageloads are made. Thus we must calculate the remaining time when we set the notification
          const lastLoginExpiration = Number(Storage.get('lastLoginExpiration'));
          const now = (new Date()).getTime();
          let time = (lastLoginExpiration - now);

          getDictionaryText('LastLoginDateLabel', (label) => {
            document.dispatchEvent(new CustomEvent('toast:open', {
              detail: {
                text: `${label} ${user.customerInfo.prevLoginTime}`,
                id: 'latest-login-time',
              }
            }));

            setTimeout(() => {
              document.dispatchEvent(new CustomEvent('toast:close', { detail: { id: 'latest-login-time' } }));
            }, time);
          });
        }
      } else {
        document.dispatchEvent(new CustomEvent('toast:close'));
      }
    });
  };

  const initHeaderNotifications = (triggerUserUpdated) => {
    const documentReady = () => {
      if (LoginCache.isLoggedIn() || triggerUserUpdated) {
        userUpdated();
      }
    };

    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', documentReady);
    } else {
      documentReady();
    }
  };

  const initialize = async () => {
    CrossWindowEvents.subscribe('ds.event.getUserDataFromSitecore', () => {
      initHeaderNotifications(true);
    });

    CrossWindowEvents.subscribe('ds.event.userLoggedOut', () => {
      document.dispatchEvent(new CustomEvent('toast:close'));
    });

    Event.subscribe('user-data-refreshed', () => {
      userUpdated();
    });

    const loggedIn = await IsLoggedIn();

    if (loggedIn) {
      userUpdated();
    }
  };

  initialize();

});
