defineDs('DanskeSpil/Domain/Feature.Navigation/Scripts/NavigationScroll', [], function () {
  const navigation = document.querySelector('.js-navigation');

  if (!navigation) {
    return;
  }

  const safeZoneTop = 250; // Always show Navigation when scrolling on the page is less than this amount in pixels.
  let isNavigationVisible = true;
  let previousScrollY = 0;

  const showNavigation = (toggle) => {
    navigation.classList.toggle('show', toggle);
    navigation.classList.toggle('hide', !toggle);

    if (isNavigationVisible !== toggle) {
      isNavigationVisible = toggle;

      if (!isNavigationVisible) {
        // close any open main menu submenus
        document.dispatchEvent(new CustomEvent('navigation:mainMenu:closeAllSubMenus'));
      }
    }
  };

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY < safeZoneTop) {
      showNavigation(true);
      return;
    }

    const userScrollsUpwards = currentScrollY <= previousScrollY;
    showNavigation(userScrollsUpwards);
    previousScrollY = currentScrollY;
  };

  const initialize = () => {
    document.addEventListener('navigation:scroll', handleScroll);
    document.addEventListener('navigation:scroll:show', () => showNavigation(true));

    setInterval(() => {
      if (!isNavigationVisible && window.scrollY < safeZoneTop) {
        showNavigation(true);
      }
    }, 1000);

  };

  initialize();

});


