defineDs('DanskeSpil/Domain/Feature.Components/Scripts/Toast', [
  'Shared/Framework/Mithril/Scripts/Helpers/Storage',
], function (Storage) {
  const toasts = document.querySelector('.js-toasts');

  if (!toasts) return;

  var isNotificationDismissed = (id) => Storage.get(`notifications-item-${id}`) === 'dismissed';

  const openToast = ({ text, id, cbClose }) => {
    if (!text || isNotificationDismissed(id)) return;
    makeToast(text, id, cbClose);
  };

  const removeToast = (toast) => {
    toast.classList.remove('ds-toast--open');
    setTimeout(() => {
      toast.remove();
    }, 340);
  };

  const closeToastWithId = ({ id }) => {
    const toast = document.getElementById(`toast-${id}`);
    if (!toast) return;
    removeToast(toast);
  };

  const closeAllToasts = () => {
    const allToasts = toasts.querySelectorAll('.ds-toast');
    allToasts.forEach((toast) => {
      removeToast(toast);
    });
  };

  const makeToast = (text, id, cbClose) => {
    let toast = document.getElementById(`toast-${id}`);

    if (!toast) {
      toast = document.createElement('div');
      if (id) toast.id = `toast-${id}`;
      toast.classList.add('ds-toast');
      const span = document.createElement('span');
      toast.appendChild(span);

      const close = document.createElement('button');
      close.innerHTML = `    
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M18 6 6 18"/>
            <path d="m6 6 12 12"/>
        </svg>`;
      close.classList.add('ds-toast__close');
      toast.appendChild(close);

      close.addEventListener('click', (ev) => {
        ev.preventDefault();
        cbClose?.();
        removeToast(toast, id);
      });

      toasts.appendChild(toast);

      setTimeout(() => {
        toast.classList.add('ds-toast--open');
      }, 100);
    }

    toast.querySelector('span').innerHTML = text;
  };

  const initialize = () => {
    document.addEventListener('toast:open', ({ detail: { text, id, cbClose } }) => {
      openToast({ text, id, cbClose });
    });

    document.addEventListener('toast:close', (event) => {
      if (event.detail?.id) {
        closeToastWithId({ id: event.detail.id });
      } else {
        closeAllToasts();
      }
    });
  };

  initialize();

});
