defineDs('DanskeSpil/Domain/Feature.Components/Scripts/Utils/IsLoggedIn', [
  'Common/Framework/EventHandling/Scripts/Event',
], (Event) => {

  return (timeout = 30000) => {
    return new Promise((resolve) => {
      // Check if the body element has the 'is-logged-in' class
      if (document.querySelector('body.is-logged-in')) {
        // If the class is present, resolve the promise with true
        resolve(true);
      } else {
        // If the class is not present, set up an event handler for the 'pageload-auth-synced' event
        const handleEvent = () => {
          // Once the event is fired, unsubscribe from it to avoid any potential memory leaks
          Event.unsubscribe('pageload-auth-synced', handleEvent);
          // Check again if the body element has the 'is-logged-in' class and resolve the promise accordingly
          resolve(!!document.querySelector('body.is-logged-in'));
        };

        // Subscribe to the 'pageload-auth-synced' event
        Event.subscribe('pageload-auth-synced', handleEvent);

        // Set up a timeout to reject the promise if the event doesn't fire within the specified time
        setTimeout(() => {
          Event.unsubscribe('pageload-auth-synced', handleEvent);  // Cleanup the event listener
        }, timeout);
      }
    });
  };

});
